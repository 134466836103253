import config from 'config';
import { authHeader } from '../../helper';

export const admControlSeguimiento = {
  detalleVentas,
  detalleVentasSorteosPasados
}

function detalleVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/controlseg`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentasSorteosPasados(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/controlsegSorteosPasados`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}
