function calcPageHeight(rows) {
  const headerSize = 105; // altura de la cabecera del ticket
  const footerSize = 60;  // altura del pie de pagina del ticket
  const rowHeight = 21; // altura de cada fila
  const totalRowHeight = rowHeight; // altura de la fila de totales
  const headHeight = rowHeight; // altura de la cabecera de las columnas

  return headerSize + (rows * rowHeight) + totalRowHeight + headHeight + footerSize;
}

const pageWidth = 224;
const minHeight = pageWidth;

export const getTicketFormat = (rows) => {
  const calcHeight = calcPageHeight(rows);
  const pageHeight = calcHeight < minHeight? minHeight : calcHeight;
  let format = [ pageWidth, pageHeight ];
  return format;
}
