import config from "config";
import { authHeader } from "../../helper";

export const useService = {
  login,
  token,
  logout,
  loginStudent,
  changePassword
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/webapi/v1/auth`, requestOptions)
    .then((res) => res.json())
    .then((data) => data.data)
    .then((usuario) => {
      // if (usuario.fuente==5) {
      //     // store usuario details and jwt token in local storage to keep usuario logged in between page refreshes
      //     localStorage.setItem('usuario', JSON.stringify(usuario));
      // }
      localStorage.setItem("usuario", JSON.stringify(usuario));

      return usuario;
    });
}

function changePassword(email, password, newPassword) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, newPassword }),
  };

  return fetch(`${config.apiUrl}/webapi/v1/changepassword`, requestOptions)
  .then((res) => res.json())
  .then((data) => data.data)
  .then((usuario) => {
    
    // localStorage.setItem("usuario", JSON.stringify(usuario));

    return usuario;
  });
}

function loginStudent(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/webapi/authstudent`, requestOptions)
    .then((res) => res.json())
    .then((usuario) => {
      // store usuario details and jwt token in local storage to keep usuario logged in between page refreshes
      localStorage.setItem("estudiante", JSON.stringify(usuario));
      return usuario;
    });
}

function token(dataform) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": localStorage.getItem("usuario")
        ? JSON.parse(localStorage.getItem("usuario")).token
        : JSON.parse(localStorage.getItem("estudiante")).token,
    },
    body: JSON.stringify(dataform),
  };

  if (localStorage.getItem("usuario")) {
    return fetch(`${config.apiUrl}/webapi/tokenauth`, requestOptions)
      .then((res) => res.json())
      .then((tokens) => {
        // store usuario details and jwt token in local storage to keep usuario logged in between page refreshes
        // localStorage.setItem('usuario', JSON.stringify(tokens));
        return tokens;
      });
  } else {
    return fetch(`${config.apiUrl}/webapi/tokenauthstd`, requestOptions)
      .then((res) => res.json())
      .then((tokens) => {
        // store usuario details and jwt token in local storage to keep usuario logged in between page refreshes
        // localStorage.setItem('usuario', JSON.stringify(tokens));
        return tokens;
      });
  }
}

function logout() {
  localStorage.removeItem("usuario");
  localStorage.removeItem("modulo");
  localStorage.removeItem("intentos");
  localStorage.removeItem("estudiante");
}

/*function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}*/
