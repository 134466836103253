import config from 'config';
import { authHeader } from '../../helper';

export const admConsultaAgencia = {
  recursos,
  detalleVentas,
  resumenDetalleAgente
}

function recursos(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consagencia`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consagenciaSorteosPasados`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function resumenDetalleAgente(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consagenciaResumenDetalleAgente`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

