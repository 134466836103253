import { userConstants } from "../constants";
import { useService } from "../services";
import { alertActions } from "./";
import { history } from "../../helper";

export const loginAction = {
  login,
  logout,
  loginStudent,
};

function login(email, password) {
  return (dispatch) =>
    new Promise(function (resolve, reject) {
      dispatch({
        type: userConstants.LOGIN_REQUEST,
        payload: email,
        module: 0,
      });

      useService.login(email, password).then(
        (usuario) => {
          if (usuario.verifica == 1) {
            dispatch({
              type: userConstants.LOGIN_SUCCESS,
              payload: usuario.token,
              module: 789,
            });
            history.push("/");
            window.location.href = "/";
          } else {
            dispatch({
              type: userConstants.LOGIN_FAILURE,
              payload: usuario.nombre,
            });
            // return usuario;
            resolve(usuario);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
    });

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  useService.logout();
  return { type: userConstants.LOGOUT };
}

function loginStudent(email, password) {
  return (dispatch) => {
    dispatch({
      type: userConstants.LOGIN_REQUEST_STUDENT,
      payload: email,
      module: 0,
    });

    useService.loginStudent(email, password).then(
      (usuario) => {
        dispatch({
          type: userConstants.LOGIN_SUCCESS_STUDENT,
          payload: usuario.token,
          module: 789,
        });
        history.push("/");
        window.location.href = "/";
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}
