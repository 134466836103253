export const cmsConstants = {
    MENUWEB_REQUEST: 'MENUWEB_REQUEST',
    MENUWEB_SUCCESS: 'MENUWEB_SUCCESS',
    MENUWEB_FAILURE: 'MENUWEB_FAILURE',

    GUARDARBLOCK_REQUEST: 'GUARDARBLOCK_REQUEST',
    GUARDARBLOCK_SUCCESS: 'GUARDARBLOCK_SUCCESS',
    GUARDARBLOCK_FAILURE: 'GUARDARBLOCK_FAILURE',

    DOCUMENTOBLOCK_REQUEST: 'DOCUMENTOBLOCK_REQUEST',
    DOCUMENTOBLOCK_SUCCESS: 'DOCUMENTOBLOCK_SUCCESS',
    DOCUMENTOBLOCK_FAILURE: 'DOCUMENTOBLOCK_FAILURE',

    INFOWEB_REQUEST: 'INFOWEB_REQUEST',
    INFOWEB_SUCCESS: 'INFOWEB_SUCCESS',
    INFOWEB_FAILURE: 'INFOWEB_FAILURE',

    FRONTEND_COMPONENT_HF_ACTIVE: 'FRONTEND_COMPONENT_HF_ACTIVE',
    FRONTEND_COMPONENT_HF_DESACTIVE: 'FRONTEND_COMPONENT_HF_DESACTIVE'
}