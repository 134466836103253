export function authHeader() {
    // return authorization header with jwt token
    let usuario = JSON.parse(localStorage.getItem('usuario'));

    if (usuario && usuario.token) {
        return { 
            'Content-Type': 'application/json',
            'x-access-token': usuario.token
        };
    } else {
        return {};
    }
}

export function authHeaderEstudiante() {
    // return authorization header with jwt token
    let usuario = JSON.parse(localStorage.getItem('estudiante'));

    if (usuario && usuario.token) {
        return { 
            'Content-Type': 'application/json',
            'x-access-token': usuario.token
        };
    } else {
        return {};
    }
}