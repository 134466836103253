import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';

export const VideosStdComponent = (props) => {

	const [codmaes, setcodmaes] = useState(0);

	return (
		<div>
		  <Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop="static"
			keyboard={false}
			size="xl"
		  >
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter"> Lista de Videos </Modal.Title>
          		<button type="button" className="close" onClick={props.handleClose}><i className="mdi mdi-window-close mdi-24px mdi-design-close"></i></button>
			</Modal.Header>
			<Modal.Body>
				<div className="row ml-0">
					<div className="col-md-3 p-0 m-0">
						<div className="card-custom">
							<div className="card-header-custom">
								Listado de Docentes
							</div>
							<div className="card-body-custom">
								<ul>
									{props.mvideos.filter(ite => ite.idtabla==1).map((v, idx) => {
										return <li key={idx} onClick={() => setcodmaes(v.idcod)}>{v.strmateria}</li>
									})}
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-9">
						<ul className="list_video_public">
							{props.mvideos.filter(ite => ite.idtabla==2 && ite.idtaca09==codmaes).map((v, idx) => {
								let spliturl = v.strnombrestudent.split('?v=')
								return (
									<li key={idx}>
										<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${spliturl[1]}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
										<div className="row info text-left mt-1">
											<span className="col-xl-12 col-md-12 col-sm-12">{v.strmateria}</span>
										</div>
										<div className="info text-left mt-1 mb-3">
											<span className="text-danger font-weight-bold">DISPONIBLE HASTA {v.dtfecha}</span>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</Modal.Body>
		  </Modal>
		</div>
	  );
}