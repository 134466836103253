import config from 'config';
import { authHeader } from '../../helper';

export const admFactor = {
  listFactor,
  actualizarFactor
}

function listFactor() {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/factor`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function actualizarFactor(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/factor`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }
