import React from 'react';
import './InfoPremio.css';
import { number_format } from '../';

function InfoPremio({ premio }) {

  return (
    <div className="row m-0 my-3">
      <div className="col p-0" >
        <div className="InfoPremio">
          <table className="InfoPremio__table" id="InfoPremio" >
            <thead>
              <tr>
                <th colSpan="2" >
                  { premio.name_sorteo }
                </th>
              </tr>
            </thead>
            <tbody>
              {/*  */}
              <tr>
                <td>
                  Núm. prem.
                </td>
                <td>
                  { premio.num_024 }
                </td>
              </tr>

              {/*  */}
              <tr>
                <td>
                  Premio
                </td>
                <td>
                  { `C$ ${ number_format(premio.premio_024, 2, '.', ',') }` }
                </td>
              </tr>
              
              {/*  */}
              <tr>
                <td>
                  Ventas
                </td>
                <td>
                  { `C$ ${ number_format(premio.mtototvendido, 2, '.', ',') }` }
                </td>
              </tr>

              {/*  */}
              <tr className={ premio.mto_inv_5074 == 0? 'd-none' : '' } >
                <td>
                  % Comisión
                </td>
                <td>
                  { number_format(premio.mto_inv_2549, 2, '.', ',') }
                </td>
              </tr>

              {/*  */}
              <tr className={ premio.mto_inv_5074 == 0? 'd-none' : '' } >
                <td>
                  Mto. Com.
                </td>
                <td>
                  { `C$ ${ number_format(premio.mto_inv_5074, 2, '.', ',') }` }
                </td>
              </tr>
              
              {/*  */}
              <tr>
                <td>
                  Saldo
                </td>
                <td className={ premio.dif_024 < 0? 'text-danger' : '' }>
                  { `C$ ${ number_format(premio.dif_024, 2, '.', ',') }` }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InfoPremio;
