import config from "config";
import { authHeader } from "../../helper";

export const admAgencias = {
  getListAgencias,
  postSavedataAgencias,
  deleteAgencias
};

function getListAgencias() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/webapi/v1/agencias`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
}

function postSavedataAgencias(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/webapi/v1/agencias`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
}

function deleteAgencias(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/webapi/v1/deleteagencias`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
}
