import React, { useState, useRef } from 'react';
import { DropdownButton, Dropdown, ButtonGroup  } from 'react-bootstrap';
import { cmsServices } from '../redux/services'
import { useToasts } from 'react-toast-notifications'

export const UploadCustomImages = (props) => {

    const [editar, setEditar] = useState(false);
    const [addContenido, setAddContenido] = useState(false);
    const [verContenidoActivo, setVerContenidoActivo] = useState(false);
    const [nombreGuardarCarpeta, setNombreGuardarCarpeta]= useState( props.titulo );
    const { addToast } = useToasts()
    const [strfile, setStrFile] = useState(null);
    const imageInputRef = useRef();

    const actualizaCarpeta = (cod) => {
       
        let info = {
            cod: cod,
            strnombre: nombreGuardarCarpeta
        };

        cmsServices.powstGuardarAudioVisual(info)
        .then(result => {
            addToast('Datos Actualizados con exito!.', { appearance: 'success', autoDismiss: true, });
            setEditar(false);
        })
        .catch(err => console.log('Error en la petición: ', err));
    }

    const eliminaCarpeta = (cod) => {
        props.cpsetStrCarpeta(nombreGuardarCarpeta);
        props.cpsetCodCarpeta(cod);
        props.cpsetModalShowEliminaCarpeta(true);
    }

    const subirimagenes = (cod) => {
        
        if (strfile===null) {
            addToast('Debe de seleccionar por lo menos una imagen.', { appearance: 'error', autoDismiss: true, });
        } else {
            var formData = new FormData();
            formData.append('cod', cod);
            for(var x = 0; x < strfile.length; x++) {
                formData.append('imgfile', strfile[x])
            }

            cmsServices.powstGuardarAudioVisualImagenes(formData)
            .then(result => {

                console.log(result);

                // let contenidoimg = result.contenidoimgvid.map(data => {
                //     return {
                //         codid: data.codid,
                //         idsegmenu: data.idsegmenu,
                //         strimg: data.strimg,
                //         urlstrimg: config.apiUrl+'/images/galeria/'+ data.strimg,
                //     }
                // });
                // props.cpsetDatosGeneralesImagenes(contenidoimg);
                addToast('Datos Subidas con exito!.', { appearance: 'success', autoDismiss: true, });
            })
            .catch(err => console.log('Error en la petición: ', err));
        }
        
    }

    const handleEliminaInput = () => {
        imageInputRef.current.value = "";
        setStrFile(null);
    }
    
    const verContenido = (cod) => {
        props.cpsetDatosContenidoFilterImagen({
            cod: cod,
            nombre: nombreGuardarCarpeta
        });
        setVerContenidoActivo(!verContenidoActivo);
    }


    return (
        <li id={props.codigo}>
            <div className="row">
                
                <div className="col-sm-10 d-flex align-items-center" style={{ fontWeight: '700'}}>
                    {editar? <input type="text" name="editnameaudiovisual" className="form-control" defaultValue={ nombreGuardarCarpeta } onChange={event => setNombreGuardarCarpeta(event.target.value)} /> : nombreGuardarCarpeta }
                </div>
                <div className="col-sm-2">
                    <DropdownButton
                        as={ButtonGroup}
                        key="down" drop="down"
                        variant={`${verContenidoActivo?'success':'primary'}`}
                        title=""
                    >
                        <Dropdown.Item eventKey="1" onClick={() => setEditar(!editar)}> <i className="mdi mdi-pencil mr-2"></i>Editar</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => setAddContenido(!addContenido)}><i className="mdi mdi-progress-upload mr-2"></i> Agregar Contenido</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => verContenido(props.codigo)}><i className="mdi mdi-image-album mr-2"></i> Ver Contenido</Dropdown.Item>
                        <Dropdown.Item eventKey="3" bsPrefix="dropdown-item dropdown-item-color-red" onClick={() => eliminaCarpeta(props.codigo)}> <i className="mdi mdi-delete-forever mr-2"></i> Eliminar</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className={`col-sm-12 my-5 text-right p-0 ${editar?'d-block':'d-none'}`}>
                    <button type="button" className="btn btn-success" onClick={() => actualizaCarpeta(props.codigo)}>Actualizar Nombre</button>
                </div>
            </div>
            <div className={`p-3 my-3 paso-dropdown ${addContenido?'active':''}`}>
                <div className="form-group">
                    <label forhtml="text">Buscar elementos a Galeria Audio Visual</label>
                    <div className="row">
                        <div className="col-md-11">
                            <input type="file" name="file[]" className="form-control" multiple onChange={(e) => setStrFile(e.target.files)} ref={imageInputRef} />
                        </div>
                        <div className="col-md-1">
                            <button type="button" className="btn btn-danger" onClick={() => handleEliminaInput()}> <i className="mdi mdi-close"></i> </button>
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <button type="button" className="btn btn-primary" onClick={() => subirimagenes(props.codigo)}>Subir a Galeria Audio Visual</button>
                </div>
            </div>
            <hr/>
        </li>
    )
}