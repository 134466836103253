import config from 'config';
import { authHeader } from '../../helper';

export const admAgentes = {
  getListaAgentes,
  postSavedataAgentes,
  actualizagridestados,
  listaLimiteVenta,
  guardaLimiteVenta,
  eliminaLimiteVenta
}

function eliminaLimiteVenta(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/eliminaLimiteVenta`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function guardaLimiteVenta(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/guardaLimiteVenta`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function listaLimiteVenta(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/listaLimiteVenta`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function actualizagridestados(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
    
    return fetch(`${config.apiUrl}/webapi/v1/updateagente`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function postSavedataAgentes(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/agente`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function getListaAgentes(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/listagente`, requestOptions)
    .then(res => res.json())
    .then(json => {
      return json;
    });
}
