import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

const { SearchBar } = Search;

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2 my-3">
        Mostrando { from } a { to } de { size } Resultados
    </span>
);

const pageListRenderer = ({
  pages,
  disable,
  title,
  onPageChange
}) => {
  const pageWithoutIndication = pages;

  const handleClick = (e, p) => {
    e.preventDefault();
    onPageChange(p.page);
  };

  return (
    <div className="d-flex flex-wrap py-2 mr-3">
      {
        pageWithoutIndication.map((p, idx) => (
          <a key={idx}  href="#" className="btn btn-hover-primary mr-1 my-1" onClick={ (e) => handleClick(e, p) }>{ p.page }</a>
        ))
      }
    </div>
  );
};

function customMatchFunc({
    searchText,
    value,
    column,
    row
  }) {
    if (typeof value !== 'undefined') {
      return value.startsWith(searchText);
    }
    return false;
}

const paginacion = paginationFactory({
  paginationSize: 4,
  alwaysShowAllBtns: true,
  pageStartIndex: 1,
  firstPageText: 'Primero',
  prePageText: 'Retro.',
  nextPageText: 'Prox.',
  lastPageText: 'Ultimo',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  pageListRenderer,
  sizePerPageList: [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '20', value: 20 }
  ]
});

export const Datatable = (props) => {

    const [ vcoid, setVcoid ] = useState(props.coid);
    const [ vckey, setVcKey ] = useState(props.dkey);
    const [ vsorted, setvsorted ] = useState(props.defaultSorted);
    const [ vexpandRow, setvexpandRow ] = useState(props.expandRow);

    useEffect(() => {
      let tablePagination = document.getElementsByClassName('react-bootstrap-table-pagination-total')[0].parentNode;
      tablePagination.className = "d-flex flex-wrap py-2 mr-3";
      tablePagination.parentNode.className = "d-flex justify-content-between align-items-center flex-wrap";

    }, []);

    return (
        <div className="my-4">
          {props.searchEnable==true ? 
            <ToolkitProvider
                bootstrap4
                keyField={vckey}
                data={ props.data }
                columns={ props.columns }
                search={ { customMatchFunc } }
                id={vcoid }
                >
            {
                props => (
                <div>
                    <div className="row mb-6 m-0">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 p-0">
                        <SearchBar { ...props.searchProps } placeholder="Buscar en todos los campos" />
                        <small className="form-text text-muted"><b>Buscar</b> en todos los campos</small>
                      </div>
                    </div>

                    <BootstrapTable
                      { ...props.baseProps }
                      keyField={vckey}
                      id={ vcoid }
                      striped
                      hover
                      bordered
                      noDataIndication="No hay datos en la tabla"
                      wrapperClasses="table-responsive"
                      defaultSorted={vsorted}
                      pagination={ paginacion }
                      expandRow={ vexpandRow }
                      filter={ filterFactory() }
                      filterPosition="top"
                    />
                </div>
                )
            }
            </ToolkitProvider>
          : 
            <ToolkitProvider
                bootstrap4
                keyField={vckey}
                data={ props.data }
                columns={ props.columns }
                search={ { customMatchFunc } }
                id={ vcoid }
                >
            {
                props => (
                <div>

                    <BootstrapTable
                      { ...props.baseProps }
                      keyField={vckey}
                      id={ vcoid }
                      striped
                      hover
                      bordered
                      noDataIndication="No hay datos en la tabla"
                      wrapperClasses="table-responsive"
                      defaultSorted={vsorted}
                      pagination={ paginacion }
                      expandRow={ vexpandRow }
                      filter={ filterFactory() }
                      filterPosition="top"
                    />

                </div>
                )
            }
            </ToolkitProvider>
          }
        </div>
    )
}