import config from 'config';
import { authHeader } from '../../helper';

export const admConsultaVendedor = {
  recursos,
  detalleVentas,
  detalleVentasVendedor,
  eliminarDetalleVenta
}

function recursos(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consvendedor`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consvendedorSorteosPasados`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentasVendedor(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consvendedorVendedor`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function eliminarDetalleVenta(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/consvendedorEliminarDetalleVenta`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}
