import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

const { SearchBar } = Search;
function customMatchFunc({
    searchText,
    value,
    column,
    row
  }) {
    if (typeof value !== 'undefined') {
      return value.startsWith(searchText);
    }
    return false;
}

export const Datatable2 = (props) => {

    const [ vcoid, setVcoid ] = useState(props.coid);
    const [ vckey, setVcKey ] = useState(props.dkey);
    const [ vsorted, setvsorted ] = useState(props.defaultSorted);
    const [ vexpandRow, setvexpandRow ] = useState(props.expandRow);

    return (
        <div className="my-4">
          {props.searchEnable==true ? 
            <ToolkitProvider
                bootstrap4
                keyField={vckey}
                data={ props.data }
                columns={ props.columns }
                search={ { customMatchFunc } }
                id={vcoid }
                >
            {
                props => (
                <div>
                    <div className="row mb-6 m-0">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 p-0">
                        <SearchBar { ...props.searchProps } placeholder="Buscar en todos los campos" />
                        <small className="form-text text-muted"><b>Buscar</b> en todos los campos</small>
                      </div>
                    </div>

                    <BootstrapTable
                      { ...props.baseProps }
                      keyField={vckey}
                      id={ vcoid }
                      striped
                      hover
                      bordered
                      noDataIndication="No hay datos en la tabla"
                      wrapperClasses="table-responsive"
                      defaultSorted={vsorted}
                      expandRow={ vexpandRow }
                      filter={ filterFactory() }
                      filterPosition="top"
                    />
                </div>
                )
            }
            </ToolkitProvider>
          : 
            <ToolkitProvider
                bootstrap4
                keyField={vckey}
                data={ props.data }
                columns={ props.columns }
                search={ { customMatchFunc } }
                id={ vcoid }
                >
            {
                props => (
                <div>

                    <BootstrapTable
                      { ...props.baseProps }
                      keyField={vckey}
                      id={ vcoid }
                      striped
                      hover
                      bordered
                      noDataIndication="No hay datos en la tabla"
                      wrapperClasses="table-responsive"
                      defaultSorted={vsorted}
                      expandRow={ vexpandRow }
                      filter={ filterFactory() }
                      filterPosition="top"
                    />

                </div>
                )
            }
            </ToolkitProvider>
          }
        </div>
    )
}
