import { userConstants } from "../constants";

let usuario = JSON.parse(localStorage.getItem("usuario"));
let estudiante = JSON.parse(localStorage.getItem("estudiante"));
const initialState = usuario
  ? { loggedIn: true, usuario: usuario, only_module: 1 }
  : estudiante
  ? { loggedIn: true, estudiante: estudiante }
  : {};
  
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        logginIn: true,
        usuario: action.payload,
        only_module: action.module,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        usuario: action.payload,
        only_module: action.module,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.UPDATE_MODULE:
      return {
        only_module: action.payload,
      };
    case userConstants.LOGIN_REQUEST_STUDENT:
      return {
        logginIn: true,
        usuario: action.payload,
        only_module: action.module,
      };
    case userConstants.LOGIN_SUCCESS_STUDENT:
      return {
        loggedIn: true,
        usuario: action.payload,
        only_module: action.module,
      };
    case userConstants.LOGIN_FAILURE_STUDENT:
      return {};
    default:
      return state;
  }
}
