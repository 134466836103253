import React, { useState } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PrivateRoute } from './components'
import { history } from './helper'
import Loadable from '@loadable/component'

import '@mdi/font/css/materialdesignicons.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './vendor/css/bootstrap.css';
import './vendor/css/webcss.min.css';
import './vendor/css/themes/layout/header/base/light.min.css';
import './vendor/css/themes/layout/brand/dark.min.css';
import './vendor/css/themes/layout/aside/dark.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import { routes_web } from './routes';

// Containers
const AdminLayout = Loadable(() => import('./layout/Administrativo'));

const App = () => {

  const dispatch = useDispatch();
  const activeFrontend = useSelector(state => state.hfactive.active)
  const [locallogin, Locallogin] = useState('');

  return (
    <div className="App">

      <Router history={history}>
        <Switch>
          {routes_web.map((route, idx)=>{
              return (<Route 
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
              />)
          })}
          <PrivateRoute path="/" name="Control" component={AdminLayout} />          
        </Switch>
      </Router>
        
      {/* <Redirect from="/" to="/inicio" /> */}
    </div>
  )
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

export default connect(mapStateToProps)(App);
