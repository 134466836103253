import config from 'config';
import { authHeader } from '../../helper';

export const admReporteConsolidado = {
  resumenVentas
}

function resumenVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/repconsol`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

// function detalleVentasSorteosPasados(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data)
//   };

//   return fetch(`${config.apiUrl}/webapi/v1/controlsegSorteosPasados`, requestOptions)
//   .then(res => res.json())
//   .then(data => {
//     return data;
//   });
// }
