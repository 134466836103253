export const downloadFiles = (urlFile, nombre) => {
    fetch(urlFile)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = nombre;
                a.click();
            });
    });
}