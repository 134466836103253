import React from 'react';
import ReactDOM, { hydrate } from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './redux';
import { ToastProvider } from 'react-toast-notifications';
// import { loadableReady } from '@loadable/component'

import './index.css';
import App from './App';

ReactDOM.render(
  <Provider store={ store }>
    <ToastProvider
      autoDismiss
      autoDismissTimeout={6000}
      placement="top-center" >
        <App />
    </ToastProvider>
  </Provider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   loadableReady(() => {
//     hydrate(
//       <Provider store={ store }>
//       <ToastProvider
//         autoDismiss
//         autoDismissTimeout={6000}
//         placement="top-center"
//       >
//         <App />
//       </ToastProvider>
//       </Provider>,
//       document.getElementById('root')
//     )
//   })
// );
