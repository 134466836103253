export const userConstants = {
    LOGIN_REQUEST: 'USUARIO_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USUARIO_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USUARIO_LOGIN_FAILURE',

    LOGOUT: 'USUARIO_LOGOUT',

    UPDATE_MODULE: 'UPDATE_MODULE_REQUEST',

    LOGIN_REQUEST_STUDENT: 'LOGIN_REQUEST_STUDENT', 
    LOGIN_SUCCESS_STUDENT: 'LOGIN_SUCCESS_STUDENT', 
    LOGIN_FAILURE_STUDENT: 'LOGIN_FAILURE_STUDENT', 
}