import config from 'config';
import { authHeader } from '../../helper';

export const admReservaNumeros = {
  getreservanum,
  guardareservanum
}

function getreservanum() {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/getreservanum`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

function guardareservanum(data) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(`${config.apiUrl}/webapi/v1/guardareservanum`, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }
