const dayInMillis=24*3600000;

export function compareDates(date1,date2) {
  // Nos quedamos con los días completos pasados desde el 1 de enero de 1970
  let days1=Math.floor(date1.getTime()/dayInMillis);
  let days2=Math.floor(date2.getTime()/dayInMillis);
  // comparamos los días
  if (days1>days2) {
    return 1;
  } else if (days1<days2) {
    return -1;
  }
  return 0;
}

export function parseDate(dateString) {
  return new Date(Date.parse(dateString));
}
