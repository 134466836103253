import React from 'react';
import Loadable from '@loadable/component'

/*
 * WebSites     Misión Visión
 */

const Login = Loadable(() => import('../layout/Login'));



export const routes_web = [
  {
    path: '/login', exact: true, name: 'Inicio de Sesion', component: Login
  },
];
