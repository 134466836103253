import { cmsConstants } from '../constants'

export function cmsmenudash(state = {}, action) {
    switch (action.type) {
        case cmsConstants.MENUWEB_REQUEST:
            return {
                datosmeudash: action.payload
            };
        case cmsConstants.MENUWEB_SUCCESS:
            return {
                datosmeudash: action.payload
            };
        default:
            return state;
    }
}

export function frontal(state = {}, action) {
    switch (action.type) {
        case cmsConstants.INFOWEB_REQUEST:
            return action.payload;        
        case cmsConstants.INFOWEB_SUCCESS:
            return action.payload;
        default:
            return state;
    }
} 

export function blocks(state = {}, action) {
    switch (action.type) {
        case cmsConstants.GUARDARBLOCK_REQUEST:
            return {
                block: action.payload
            };        
        case cmsConstants.GUARDARBLOCK_SUCCESS:
            return {
                block: action.payload
            };
        case cmsConstants.DOCUMENTOBLOCK_REQUEST:
            return {
                block: action.payload
            };
        case cmsConstants.DOCUMENTOBLOCK_SUCCESS:
            return {
                block: action.payload
            };
        default:
            return state;
    }
} 

export function hfactive(state = {
    active: true
}, action) {
    switch (action.type) {
        case cmsConstants.FRONTEND_COMPONENT_HF_ACTIVE:
            return {
                active: action.payload
            };        
        case cmsConstants.FRONTEND_COMPONENT_HF_DESACTIVE:
            return {
                active: action.payload
            };
        default:
            return state;
    }
} 