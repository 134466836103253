import config from 'config';
import { authHeader } from '../../helper';

export const admVentas = {
  getListaVentas,
  guardarVentas,
  eliminaVentas,
  listVendedor,
  detalleVentas,
  detalleVentasSorteosPasados
}

function getListaVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/getventas`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function guardarVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/guardventas`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function eliminaVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/eliminaVentas`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function listVendedor(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/listVendedor`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/detalleVentas`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentasSorteosPasados(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/detalleVentasSorteosPasados`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}
