import config from 'config';
import { authHeader } from '../../helper';

export const admSorteos = {
  getListaSorteos,
  postUpdateStateSorteo,
  postSavedataSorteo
}

function postSavedataSorteo(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/sorteo`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function postUpdateStateSorteo (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/sorteoState`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  }); 
}

function getListaSorteos() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/webapi/v1/sorteo`, requestOptions)
    .then(res => res.json())
    .then(json => {
      return json;
    });
}
