import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '../redux/actions'
import { useToasts } from 'react-toast-notifications'
import { useForm } from "react-hook-form";
import { useService } from '../redux/services'
// import { history } from '../helper'

export const Error401 = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, reset } = useForm({ });
    const authentication = useSelector(state => state.authentication);
    const [intentos, setIntentos] = useState(0);
    // const history = useHistory();

    const handleCerrarSesion = () => {
        // props.onHide();
        dispatch(loginAction.logout());
        location.reload();
    }

    const sumaIntentos = inte => inte + 1;

    const handleRestablecePass = (data, e) => {
        
        var submitRestablecePass = (localStorage.getItem('usuario')) ? {
            refreshToken: (!(authentication.usuario=='undefined' || authentication.usuario==undefined) ? authentication.usuario.refreshToken : ''),
            email: (!(authentication.usuario=='undefined' || authentication.usuario==undefined) ? authentication.usuario.email : ''),
            name: (!(authentication.usuario=='undefined' || authentication.usuario==undefined) ? authentication.usuario.nombre : ''),
            password: data.restablecepass
        } : {
            refreshToken: (!(authentication.estudiante=='undefined' || authentication.estudiante==undefined) ? authentication.estudiante.refreshToken : ''),
            email: (!(authentication.estudiante=='undefined' || authentication.estudiante==undefined) ? authentication.estudiante.email : ''),
            name: (!(authentication.estudiante=='undefined' || authentication.estudiante==undefined) ? authentication.estudiante.nombre : ''),
            password: data.restablecepass
        };

        console.log(submitRestablecePass)

        useService.token(submitRestablecePass)
        .then(result => {

            if (result.error) {
                addToast(result.message, { appearance: 'error', autoDismiss: true, });
                e.target.reset();
                if ("intentos" in localStorage) {
                    let lsIntentos = parseInt(localStorage.getItem('intentos'));
                    setIntentos(sumaIntentos(lsIntentos));
                    localStorage.setItem('intentos', sumaIntentos(lsIntentos));
                } else {
                    setIntentos(sumaIntentos(intentos));
                    localStorage.setItem('intentos', sumaIntentos(intentos));
                }

                if (parseInt(localStorage.getItem('intentos')) > 2) {
                    addToast('Ha superado el máximo número de intentos, Se cerrará la sesión.', { appearance: 'error', autoDismiss: true, });
                    localStorage.setItem('intentos', 0);
                    handleCerrarSesion();
                }
                return;
            }

            if ("usuario" in localStorage) {
                localStorage.setItem('usuario', JSON.stringify(result));
                localStorage.setItem('intentos', 0);
                props.onHide();
                location.reload();
            } else {
                addToast('No hay datos de la sesión.', { appearance: 'error', autoDismiss: true, });
                localStorage.setItem('intentos', 0);
                handleCerrarSesion();
                return;
            }
        });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
        <Modal.Header>
            <button type="button" className="close" onClick={props.onHide}><i className="mdi mdi-window-close mdi-24px mdi-design-close"></i></button>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(handleRestablecePass)}>
                <div className="d-flex justify-content-center text-center flex-column access-point-off">
                    <i className="mdi mdi-access-point-off"></i>
                    <h4 className="h4">
                        { props.message }
                    </h4>
                    <br/>
                    <h5>¿Quiere restablecer la sesión?</h5>

                    <div className="form-group">
                        <label forhtml="text">Ingrese su Contraseña</label>
                        {(intentos>0) ? <p className="valida_error">Intentos {intentos}</p> : ''}
                        <input type="password" className="form-control text-center" name="restablecepass"
                                ref={register({ required: true, maxLength: 20 })}
                                aria-invalid={errors.restablecepass ? "true" : "false"} autoComplete="false" />
                        {errors.restablecepass && errors.restablecepass.type==='required' && 
                                    <span className="valida_error">Este campo es requerido</span>}
                        {errors.restablecepass && errors.restablecepass.type==='maxLength' &&
                                    <span className="valida_error">Este campo debe de tener un máximo de 20 carácteres</span>}
                    </div>

                    <button type="submit" className="btn btn-primary">Restablecer Sesión</button>
                    <br/>
                    <button type="button" className="btn btn-light" onClick={handleCerrarSesion}>Cerrar Sesión</button>
                </div>
            </form>
        </Modal.Body>
        </Modal>
    );
}