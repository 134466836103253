import config from 'config';
import { authHeader } from '../../helper';

export const admMonitoreoRifaAgencia = {
  detalleVentas,
  detalleVentasSorteosPasados,
  resumenDetalleAgente
}

function detalleVentas(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/ctrlrifaagencia`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function detalleVentasSorteosPasados(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/ctrlrifaagenciaSorteosPasados`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}

function resumenDetalleAgente(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${config.apiUrl}/webapi/v1/ctrlrifaagenciaresumenDetalleAgente`, requestOptions)
  .then(res => res.json())
  .then(data => {
    return data;
  });
}
