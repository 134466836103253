import { cmsServices } from '../services'
import { cmsConstants } from '../constants'
export const cmsAction = {
    menuWebAction,
    guardarblockAction,
    documentoblockAction,
    getContentAllSiteWebAction,
    frontend_active,
    frontend_desactive
}

function menuWebAction () {
    return dispatch => {
        dispatch({ type: cmsConstants.MENUWEB_REQUEST, payload: {}});
        cmsServices.getMenuparaWeb()
        .then(
            data => {
                dispatch({type: cmsConstants.MENUWEB_SUCCESS, payload: data});
                return {
                    ok: true,
                    datos: data
                }
            }
        );
    }
}

function guardarblockAction (datos) {
    return dispatch => {
        dispatch({ type: cmsConstants.GUARDARBLOCK_REQUEST, payload: {}});
        cmsServices.guardarBlock(datos)
        .then(
            data => {
                dispatch({type: cmsConstants.GUARDARBLOCK_SUCCESS, payload: data});
                return {
                    ok: true,
                    datos: data
                }
            }
        );
    }
}

function documentoblockAction () {
    return dispatch => {
        dispatch({ type: cmsConstants.DOCUMENTOBLOCK_REQUEST, payload: {}});
        return cmsServices.documentoBlock()
        .then(
            data => {
                dispatch({type: cmsConstants.DOCUMENTOBLOCK_SUCCESS, payload: data});
                return {
                    ok: true,
                    datos: data
                }
            }
        );
    }
}

function getContentAllSiteWebAction () {
    return dispatch => {
        dispatch({ type: cmsConstants.INFOWEB_REQUEST, payload: {}});
        return cmsServices.getContentAllSiteWeb()
        .then(
            data => {
                dispatch({type: cmsConstants.INFOWEB_SUCCESS, payload: data});
                return {
                    ok: true,
                    datos: data
                }
            }
        );
    }
}

function frontend_active () {
    return dispatch => {
        dispatch({ type: cmsConstants.FRONTEND_COMPONENT_HF_ACTIVE, payload: true});
    }
}
function frontend_desactive () {
    return dispatch => {        
        dispatch({ type: cmsConstants.FRONTEND_COMPONENT_HF_DESACTIVE, payload: false});
    }
}


