import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer'
import { alert } from './alert.reducer'
import { cmsmenudash, blocks, frontal, hfactive } from './cms.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    cmsmenudash,
    blocks,
    frontal,
    hfactive
});

export default rootReducer;
